import commonHelper from '@/app/utility/common.helper.utility';
import purReqEntryForm from './purReqEntryForm';
import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';
import purchaseReqVset from './purchaseReqVset';
import HistoryRecord from '@/app/components/onlineApproval/internalWorkFlow/history/historyRecord';
import ApprovalHistory from '@/app/components/onlineApproval/internalWorkFlow/history/approvalHistory';
// import UploadDocument from '@/app/components/onlineApproval/internalWorkFlow/history/uploadDocument';
import sendForApproval from '@/app/components/onlineApproval/internalWorkFlow/history/sendForApproval';

export default {
  name: 'purchaseRequisition',
  watch: {
    currentPage: function() {
      this.getPurReqDtls();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getPurReqDtls();
    }
  },
  components: {
    purReqEntryForm,
    DatePicker,
    purchaseReqVset,
    HistoryRecord,
    ApprovalHistory,
    sendForApproval
  },
  data() {
    return {
      loader: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      operationUnit: {
        text: null,
        value: null
      },
      requiNum: null,
      preparer: null,
      buyer: null,
      requester: null,
      item: {
        text: null,
        value: null
      },
      status: {
        text: null,
        value: null
      },
      postOracleStatus: {
        text: null,
        value: null
      },
      dateFrom: null,
      dateTo: null,
      purchaseRequisitionData: [],
      purchaseRequisitionFields: [
        {
          key: 'select',
          variant: 'info',
          stickyColumn: true
        },
        {
          key: 'OU_NAME',
          label: 'Operating Unit',
          class: 'text-center'
        },
        {
          key: 'REQUISITION_NUMBER',
          label: 'Requisition Number',
          class: 'text-center'
        },
        {
          key: 'PREPARER_NAME',
          label: 'Preparer',
          class: 'text-center'
        },
        {
          key: 'REQUISITION_TYPE',
          label: 'Requisition Type',
          class: 'text-center'
        },
        {
          key: 'APPROVAL_STATUS',
          label: 'Approval Status',
          class: 'text-center'
        },
        {
          key: 'DESCRIPTION',
          label: 'Description',
          class: 'text-center'
        },
        {
          key: 'CREATED_BY',
          label: 'Created By',
          class: 'text-center'
        },
        {
          key: 'CREATION_DATE',
          label: 'Creation Date',
          class: 'text-center'
        },
        {
          key: 'POST_TO_ORACLE_STATUS_MEANING',
          label: 'Post To Oracle Status',
          class: 'text-center'
        },
        {
          key: 'CURRENCY',
          label: 'Currency',
          class: 'text-center'
        }
      ],
      // purchaseRequisitionFields: [
      //   {
      //     key: 'requisition_org',
      //     label: 'Requisition Org',
      //     class: 'text-center'
      //   },
      //   {
      //     key: 'number',
      //     label: 'Number',
      //     class: 'text-center'
      //   },
      //   {
      //     key: 'line',
      //     label: 'Line',
      //     class: 'text-center'
      //   },
      //   {
      //     key: 'item',
      //     label: 'Item',
      //     class: 'text-center'
      //   },
      //   {
      //     key: 'description',
      //     label: 'Description',
      //     class: 'text-center'
      //   },
      //   {
      //     key: 'category',
      //     label: 'Category',
      //     class: 'text-center'
      //   },
      //   {
      //     key: 'quantity',
      //     label: 'Quantity',
      //     class: 'text-center'
      //   },
      //   {
      //     key: 'uom',
      //     label: 'UOM',
      //     class: 'text-center'
      //   },
      //   {
      //     key: 'price',
      //     label: 'Price',
      //     class: 'text-center'
      //   },
      //   {
      //     key: 'amount',
      //     label: 'Amount',
      //     class: 'text-center'
      //   },
      //   {
      //     key: 'requester',
      //     label: 'Requester',
      //     class: 'text-center'
      //   },
      //   {
      //     key: 'note_to_buyer',
      //     label: 'Note To Buyer',
      //     class: 'text-center'
      //   },
      //   {
      //     key: 'justification',
      //     label: 'Justification',
      //     class: 'text-center'
      //   }
      // ],
      showPurReqEntryModal: false,
      accessButtons: {
        deleteAllow: false,
        downloadAllow: false,
        uploadAllow: false,
        editAllow: true,
        addAllow: true,
        printAllow: false,
        approvalAllow: false
      },
      unsubscribe: null,
      showValueSetModal: false,
      rowData: null,
      showPurReqVsetModal: false,
      openModalFormType: 'purReqMainForm',
      vsetName: null,
      openModalType: null,
      showHistoryBtn: false,
      selectedReqDtls: null,
      showHistory: false,
      historyType: null,
      showOpenKmModal: false,
      openkmAuthToken: null,
      showApproveRejectModal: false,
      approvalReason: null,
      rejectedReason: null,
      approvalRemarks: null,
      rejectionRemarks: null
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.rowData = null;
          this.showPurReqEntryModal = true;
        }
      }
    });
  },
  methods: {
    showHidePurReqEntryModal(flag) {
      this.showPurReqEntryModal = flag;
    },
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.dateFrom);
    },
    selectedRequisiton(item) {
      this.selectedReqDtls = Object.assign({}, item);
      this.showHistoryBtn = true;
    },
    showHideHistory(flag, historyType) {
      this.showHistory = flag;
      this.historyType = historyType;
      if (!flag) {
        this.getPurReqDtls();
      }
    },
    validateUploadDocument() {
      this.showHideHistory(true, 'sendForApproval');
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (
        this.vsetCode === appStrings.VALUESETTYPE.PARTY_REQ_APPROVAL_STATUS_VSET
      ) {
        this.status = {
          text: item.value_meaning,
          value: item.value_code
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.PURCHASE_REQ_STATUS
      ) {
        this.postOracleStatus = {
          text: item.value_meaning,
          value: item.value_code
        };
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.operationUnit.value) {
        this.operationUnit = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.requiNum) {
        this.requiNum = null;
      } else if (vsetCode === this.preparer) {
        this.preparer = null;
      } else if (vsetCode === this.buyer) {
        this.buyer = null;
      } else if (vsetCode === this.requester) {
        this.requester = null;
      } else if (vsetCode === this.item.value) {
        this.item = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.status.value) {
        this.status = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.postOracleStatus.value) {
        this.postOracleStatus = {
          text: null,
          value: null
        };
      }
    },
    getPurReqDtls() {
      // this.$v.$touch();
      // if (!this.$v.$invalid) {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        user_id: this.$store.state.auth.userId,
        requisition_number: this.requiNum,
        org_id: this.operationUnit.value,
        operating_unit: this.operationUnit.text,
        approval_status: this.status.value,
        preparer: this.preparer,
        post_to_oracle_status: this.postOracleStatus.value,
        creation_date_from: this.dateFrom,
        creation_date_to: this.dateTo
      };
      this.loader = true;
      this.$store
        .dispatch('purchaseRequisition/getPurReqDtls', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.purchaseRequisitionData = result;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
      // }
    },
    clear() {
      this.operationUnit = {
        text: null,
        value: null
      };
      this.requiNum = null;
      this.preparer = null;
      this.status = {
        text: null,
        value: null
      };
      this.postOracleStatus = {
        text: null,
        value: null
      };
      this.dateFrom = null;
      this.dateTo = null;
      this.showHistoryBtn = false;
      this.totalRows = null;
      this.purchaseRequisitionData = [];
    },
    openVsetModal(type) {
      if (type === 'operationgUnit') {
        this.vsetName = 'Operating Unit Details';
        this.openModalType = 'operatingUnit';
        this.showHidePurReqVsetModal(true);
      }
    },
    showHidePurReqVsetModal(flag) {
      this.showPurReqVsetModal = flag;
    },
    mapSelectedVsetData(item) {
      if (item.name === 'purReqOU') {
        this.operationUnit = {
          text: item.ou_name,
          value: item.ou_id
        };
      }
      this.showPurReqVsetModal = false;
    },
    rowSelected(item) {
      this.rowData = item;
      this.selectedReqDtls = Object.assign({}, item);
      this.showHidePurReqEntryModal(true);
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
